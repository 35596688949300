<template>
  <Portlet
    title="Peaksolution"
    icon="mountain"
    class="peaksolution"
  >
    <div class="peakLoginDataList">
      <LoadingPlaceholder v-if="loading" />
    
      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(peakLoginDatas)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(peakLoginDatas)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="passwordTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <PasswordViewer
                :id="props.dataItem.id"
                :password="'tempPw'"
                :peak-id="props.dataItem.id"
                :installation-id="props.dataItem.installationId"
              />
            </td>
          </template>
          <template
            slot="backendTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <a 
                v-if="props.dataItem.backendUrl"
                :href="props.dataItem.backendUrl" 
                target="_blank"
                class="alt-primary-color"
              >
                Peak Backend
              </a>
              <template v-if="props.dataItem.peakVersion">
                <br>
                <p>{{ getVersionNumber(props.dataItem.peakVersion) }}</p>
              </template>
            </td>
          </template>

          <template
            slot="jamesTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <a 
                v-if="props.dataItem.frontendJamesUrl"
                :href="props.dataItem.frontendJamesUrl" 
                target="_blank"
                class="alt-primary-color"
              >
                James Frontend
              </a>
              <template v-if="props.dataItem.jamesVersion">
                <br>
                <span>{{ props.dataItem.jamesVersion }}</span>
              </template>
              <template v-if="props.dataItem.mwVersion">
                <br>
                <span>MDLW {{ props.dataItem.mwVersion }}</span>
              </template>
            </td>
          </template>

          <template
            slot="peakTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <a 
                v-if="props.dataItem.frontendPeakUrl"
                :href="props.dataItem.frontendPeakUrl" 
                target="_blank"
                class="alt-primary-color"
              >
                Peak Frontend
              </a>
            </td>
          </template>

          <template
            slot="installationTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <router-link
                :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.componentId}`"
                class="alt-primary-color"
              >
                {{ kgm_getNestedValue(props.field, props.dataItem) }}
              </router-link>
            </td>
          </template>

          <div
            slot="customerFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="customerFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Customer"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('customerFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="abbFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="abbFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a abbreviation"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('abbFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="installationFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="installationFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Installation"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('installationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="typeFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <select
              ref="typeSelect"
              :key="`typeSelect-${props.value}`"
              :value="props.value"
              class="form-control mr-2 rounded-right alt-pointer"
              @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
              <option
                value=""
                disabled
              >
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(typek, key) in typeList"
                :key="`typeListFilterOption-${key}`"
                :selected="kgm_selectDefaultOption(props, typek, 'typeSelect')"
              >
                {{ typek }}
              </option>
            </select>
            <div
              v-if="$refs != null && $refs.typeSelect != null && ($refs.typeSelect.value != null && $refs.typeSelect.value.length != 0)"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetSelect('typeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="usernameFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="usernameFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Username"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('usernameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <template
            slot="optionsTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <button
                v-if="props.dataItem.hasLoginButton && props.dataItem.type == 'Application'"
                class="btn btn-success btn-sm"
                @click="login(props.dataItem)"
              >
                <span>Login</span>
              </button>
              <button
                title="Changelog"
                class="btn btn-light btn-sm"
                @click="changelog(props.dataItem)"
              >
                <font-awesome-icon
                  class="gray"
                  icon="file-contract"
                />
              </button>
            </td>
          </template>
        </Grid>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "PeakLoginDataList",
  components: {
    PasswordViewer: () => import('@/components/PasswordViewer.vue')
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      loginDatas: null,
       kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "type",
            operator: "contains",
            value: "Application"
          }
        ]
      },
      kgm_take: 50,
      kgm_columns: [
        {
          field: 'customer',
          filterable: true,
          filter: 'text',
          title: this.$t('customer'),
          filterCell: 'customerFilter',
        },
        {
          field: 'customerAbbreviation',
          filterable: true,
          filter: 'text',
          title: this.$t('customerAbbreviation'),
          filterCell: 'abbFilter',
        },
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: this.$t('installation'),
          filterCell: 'installationFilter',
          cell: 'installationTemplate'
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'User Type',
          filterCell: 'typeFilter',
        },
        {
          field: 'username',
          filterable: true,
          filter: 'text',
          title: 'Username',
          filterCell: 'usernameFilter',
        },
        {
          field: 'password',
          filterable: false,
          title: this.$t('password'),
          filterCell: 'passwordFilter',
          cell: 'passwordTemplate'
        },
        {
          field: 'backendUrl',
          filterable: false,
          title: 'Backend',
          cell: 'backendTemplate'
        },
        {
          field: 'frontendJamesUrl',
          filterable: false,
          title: 'James',
          cell: 'jamesTemplate'
        },
        {
          field: 'frontendPeakUrl',
          filterable: false,
          title: 'Peak',
          cell: 'peakTemplate'
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  computed: {
    typeList () {
      if (!this.peakLoginDatas) {
        return [ "" ];
      }
      let typeList = Array.from(new Set(this.peakLoginDatas.map(o=>o.type)));
      typeList = typeList.filter(function (e) {return e});
      return typeList.sort();
    },
  },
  created () {
    if(this.$route.params.name) {
      this.kgm_filter.filters.push({
            field: 'installationName',
            operator: 'contains',
            value: this.$route.params.name
          });
    }
    if(this.$route.params.abb) {
      this.kgm_filter.filters.push({
            field: 'customerAbb',
            operator: 'contains',
            value: this.$route.params.abb
          });
    }
    this.getPeakLoginData();
  },
  methods: {
    getVersionNumber (val) {
      let branchNameMatch = val.match(/git\.branchName = (\S+)/);
      let branchName = branchNameMatch ? branchNameMatch[1] : '';
      let buildNumberMatch = val.match(/bamboo\.buildNumber = (\d+)/);
      let buildNumber = buildNumberMatch ? buildNumberMatch[1] : '';
      let releasePart = '';
      if (branchName) {
        // eslint-disable-next-line no-useless-escape
        let releaseMatch = branchName.match(/\/([^\/]+)/);
        releasePart = releaseMatch ? releaseMatch[1] : '';
      }

      return releasePart && buildNumber ? `${releasePart}-${buildNumber}` : '';
    },
    changelog (data) {
      this.$router.push(`/installation/${data.installationId}/changelog`)
    },
    getDescription (val) {
      if(val) {
        return val.length > 100 ? val.substring(0, 100) + '...' : val
      }
      return null;
    },
    login (data) {
      let routeData = this.$router.resolve({path: `/installation/${data.installationId}/peak-login/${data.username}`});
      window.open(routeData.href, '_blank');
    },
    getPeakLoginData () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetPeakLogins`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.peakLoginDatas = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>
<style>
.softwareComponentLoginDataList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
}

</style>